.ev-date-picker-popup {
  max-width: 450px;
}

.ev-date-picker-popup input.ant-input {
  padding: 0 5px 0px 10px !important;
  font-size: 14px !important;
  height: 34px !important;
}

.ev-date-range-lable-box {
  height: 100%;
  font-size: 14px;
  align-items: center;
  background: #ffffff;
  display: flex;
  outline: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: border 0.2s, box-shadow 0.2s;
}

.ev-date-range-lable-box.large {
  min-height: 40px;
  font-size: 16px;
  line-height: 1.5;
}

.ev-date-range-lable-box .date-range-lable {
  padding: 0px 5px;
  white-space: nowrap;
  overflow: hidden;
}

.ev-date-range-lable-box .right-side-border {
  border-right: 1px solid #d9d9d9;
}

.ev-date-range-lable-box .left-side-border {
  border-left: 1px solid #d9d9d9;
}

.ev-date-range-lable-box .filter-icon-btn {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.ev-date-range-picker-popup .ant-popover-content {
  top: 8px;
}

.ev-date-picker-popup .ant-btn.submit-btn {
  background-color: #f16521 !important;
  height: 40px !important;
  font-weight: unset !important;
}

.ant-picker-dropdown.ev-date-picker .ant-picker-header button {
  color: rgba(0, 0, 0, 0.45) !important;
}

.ev-date-picker .ant-picker-footer button.ant-btn {
  background-color: #f16521 !important;
  font-size: 16px !important;
  height: 28px !important;
  font-weight: unset !important;
}

.ev-date-picker .ant-picker-footer .ant-btn-primary:disabled,
.ev-date-picker .ant-picker-footer .ant-btn-primary.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}

.ant-picker-dropdown.ev-date-picker .ant-picker-ranges {
  margin-bottom: 5px;
  margin-top: 5px;
}

.date-range-lable .date-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 576px) {
  .ev-date-picker-popup .Last.mins {
    display: none;
  }

  .ev-date-range-picker-popup {
    max-width: 90%;
  }

  .ev-date-picker-popup .filter-icon-btn {
    display: none;
  }
}