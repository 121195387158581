.test-ride-form button.ant-btn.back-btn {
  border: 1px solid #98a2b3 !important;
  color: #98a2b3 !important;
  height: 40px !important;
  font-weight: unset !important;
}

.test-ride-form button.ant-btn.submit-btn {
  background-color: #f16521;
  height: 40px !important;
  font-weight: unset !important;
}

.ant-picker-dropdown.test-ride-form-date-picker .ant-picker-header button {
  color: rgba(0, 0, 0, 0.45) !important;
}

.test-ride-form-date-picker .ant-picker-footer button.ant-btn {
  background-color: #f16521 !important;
  font-size: 16px !important;
  height: 28px !important;
  font-weight: unset !important;
}

.test-ride-form-date-picker .ant-picker-footer .ant-btn-primary:disabled,
.test-ride-form-date-picker .ant-picker-footer .ant-btn-primary.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}

.ant-picker-dropdown.test-ride-form-date-picker .ant-picker-ranges {
  margin-bottom: 5px;
  margin-top: 5px;
}

.test-ride-form .ant-form-item .ant-form-item-label {
  display: block;
}

.test-ride-form .ant-col,
.test-ride-form .ant-steps .ant-steps-item-title,
.test-ride-form .ant-form-item .ant-form-item-label>label {
  font-size: 16px;
}

.test-ride-form input::-webkit-outer-spin-button,
.test-ride-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.test-ride-form input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.test-ride-form .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: transparent;
  border-color: #f16521;
}

.test-ride-form .input-box .ant-card-body {
  padding: 24px 50px;
  background-color: #f5f5f5;
}

@media screen and (max-width: 480px) {
  .test-ride-form .input-box .ant-card-body {
    padding: 24px 16px;
  }
}