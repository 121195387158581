.head20 {
  font-size: 20px;
  font-weight: 600;
}

.head26 {
  font-size: 26px;
  font-weight: 600;
}

.head18 {
  font-size: 18px;
  font-weight: 600;
}

.head30 {
  font-size: 30px;
  font-weight: 600;
}

.head38 {
  font-size: 38px;
  font-weight: 600;
}

.normal-head {
  font-size: 20px;
}

.head24 {
  font-size: 24px;
  font-weight: 600;
  color: #1d2939;
}

.hr-margin {
  margin: 10px 0;
}

.widget {
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  padding: 15px;
  border: solid 1px #eee;
}

.card {
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  color: #1d2939;
  border: solid 1px #eee;
}

.grey-footer {
  background-color: #eaecf0;
  border-top: 1px solid #98a2b3;
  padding: 10px;
  border-radius: 0 0 4px 4px;
}

.grey-box {
  background: rgba(234, 236, 240, 0.45);
  border: 1px solid #eaecf0;
  padding: 10px;
  border-radius: 8px;
}

.sensor-card {
  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #eee;
  width: 100%;
  color: #1d2939;
}

.title {
  background-color: #eaecf0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px 0 0 4px;
}

.border-bottom {
  border-bottom: solid 1px #eee;
}

.vehicle-card {
  padding: 5px;
  border-radius: 2px;
  background: rgba(234, 236, 240, 0.5);
}

.mht150 {
  min-height: 150px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.most-active-ul,
.least-active-ul {
  margin: 0;
  padding: 0;
  height: 260px;
  overflow-y: scroll;
}

.most-active-ul > a {
  color: #494949;
}

.least-active-ul > a {
  color: #494949;
}

.most-active-ul > a:hover,
.least-active-ul > a:hover {
  color: #f16521;
}

.most-active-list {
  list-style-type: none;
  padding: 10px;
  margin: 10px;
  background-color: #f8f8f8;
  font-size: 14px;
}

.least-active-list {
  list-style-type: none;
  padding: 10px;
  margin: 10px;
  background-color: #f8f8f8;
  font-size: 14px;
}

.least-active-list:hover {
  background-color: #fef2ec;
}

.speed-box {
  background-color: #fcfcfc;
  border: solid 1px #eee;
  padding: 18px 12px;
  width: 100%;
  border-radius: 4px;
  color: #494949;
}

.battery-box {
  background-color: #fcfcfc;
  border: solid 1px #eee;
  padding: 18px 12px;
  width: 100%;
  border-radius: 4px;
  color: #494949;
}

.vehicles {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.vehicles:hover {
  background: rgba(0, 0, 0, 0.5);
}

.toggle-btn {
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  color: #f16521;
}

.toggle-btn:hover {
  text-decoration: underline;
}

.grey {
  color: #667085;
}

.green {
  color: green;
}

.red {
  color: #e20f0f;
}

.orange {
  color: #ef580f;
}

.divider-margin {
  margin: 10px 0;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 36px;
  line-height: 36px;
  padding: 2px 28px;
}

.ant-segmented .ant-segmented-item-selected {
  font-weight: 600;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item {
  border-radius: 4px;
}

.date {
  background-color: #fff;
  padding: 0px 30px;
  border: solid 1px #f16521;
  width: 100%;
  height: 40px;
  cursor: auto;
}

.f16 {
  font-size: 16px;
}

.ant-space-gap-col-small {
  column-gap: 0px;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  border-radius: 0 !important;
  border: solid 1px #d9d9d9;
  padding: 0 3rem;
}
