.ant-menu-light .ant-menu-item-selected {
  background-color: #1d2939;
  color: #fff;
}

.ant-menu-vertical .ant-menu-item {
  margin-inline: 3px;
}

.ant-menu .ant-menu-title-content {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
}

.flex {
  display: flex;
}

.mr075 {
  margin-right: 0.75rem;
}

.drawer-pd {
  padding: 0.75rem 0.5rem;
}

.breadcrumb-layout {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #f7f7f7;
}

.content-layout {
  margin: 0;
  min-height: 580;
  background: #f5f5f5;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d2939;
  color: #fff;
}

.layout {
  padding: 15px 16px;
}

.mr1075 {
  margin-right: 1.75rem;
}

.ml-auto {
  margin-left: auto;
}

.mob-menu {
  margin-left: auto;
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
}

.header {
  display: flex;
  align-items: center;
  background-color: #1d2939;
  color: #fff;
  padding: 0 12px 0 12px;
}