.test-ride-statistic button.ant-btn.back-btn {
  padding: 0px;
  border: 1px solid #98a2b3 !important;
  color: #98a2b3 !important;
  height: 40px !important;
  font-weight: unset !important;
}

.test-ride-statistic button.ant-btn.ant-btn-submit {
  height: 40px !important;
  font-weight: unset !important;
}

.ant-select-multiple .ant-select-selection-overflow {
  height: 36px !important;
}

.cumulative-summary-filter-card {
  background-color: #ffffff;
  padding: 12px 12px 5px;
  border-radius: 8px;
}

.cumulative-summary-filter-card .card-head {
  padding: 0px 0px 10px 5px;
  font-size: 16px;
  font-weight: 600;
}

.cumulative-summary-filter-card .lable {
  font-size: 14px;
  padding: 0 0 8px;
  white-space: initial;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.cumulative-summary-filter-card .ev-date-range-lable-box.large {
  max-height: 40px;
}

.cumulative-summary-filter-card .ride-count {
  color: #98a2b3;
  padding: 0px 10px;
}

.wheel-chart-col {
  padding: 5px;
  height: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-ride-statistic button.add-statistic-btn {
  height: 32px !important;
  font-size: 14px !important;
  color: #f16521 !important;
  background-color: #ffffff !important;
  border: 1px solid #f16521 !important;
}

.imei-values .ant-select-selection-overflow-item .ant-select-selection-item {
  padding-inline-start: 0px;
  padding-inline-end: 6px;
}

.imei-values
  .ant-select-selection-overflow-item
  .ant-select-selection-item-content {
  white-space: nowrap;
  padding: 0 4px 0 0;
  width: 62px;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
}

.mode-table .ant-empty {
  min-height: 92px;
}

.mode-table table {
  min-height: 160px;
}

.test-ride-statistic .grid {
  width: 50% !important;
  text-align: center !important;
}

@media screen and (max-width: 768px) {
  .test-ride-statistic .grid {
    width: 100% !important;
  }
}
