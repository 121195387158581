.dealer-layout {
  padding: 24px;
  min-height: 100vh;
}

.dealer-card {
  border-radius: 8px;
}

.dealer-card-body {
  padding: 15px;
  border-radius: 0 0 8px 8px;
  background: rgba(255, 255, 255, 255);
  color: #1d2939;
}

.dealer-card-head {
  border-radius: 8px 8px 0 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.date-widget {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  padding: 2px 8px;
  color: #fff;
}

.time-widget {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  padding: 6px 14px;
  color: #fff;
  font-size: 1.75rem;
}

.weekday,
.month {
  font-size: 11px;
}

.day {
  font-size: 24px;
  font-weight: 600;
}

.count-box {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 10px;
  min-height: 140px;
  color: #1d2939;
  border: solid 1px #eee;
  box-shadow: 0px 0px 10px 0.15px rgba(0, 0, 0, 0.25);
}

.f13 {
  font-size: 13px;
}

.f12 {
  font-size: 12px;
}

.head24 {
  font-size: 24px;
  font-weight: 500;
}

.head22 {
  font-size: 22px;
  font-weight: 500;
}

.head20 {
  font-size: 20px;
  font-weight: 500;
}

.head18 {
  font-size: 18px;
  font-weight: 500;
}

.head16 {
  font-size: 16px;
  font-weight: 500;
}

.head24-bold {
  font-size: 24px;
  font-weight: 600;
}

.dealer-name {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.ant-float-btn-primary .ant-float-btn-body {
  background: linear-gradient(#000, #323232, #000);
}

.ant-float-btn-group {
  background: linear-gradient(#000, #323232, #000);
  padding: 5px;
  width: auto;
}

.ant-float-btn-primary .ant-float-btn-body:hover {
  background: linear-gradient(#000, #464646, #000);
}

.ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
  width: auto;
}

.live-panel {
  border-radius: 8px;
  padding: 16px;
  background: rgba(255, 255, 255, 255);
  color: #1d2939;
}

.badge {
  background: radial-gradient(
    49.86% 49.86% at 49.9% 50.14%,
    #fdf3bf 0%,
    #ecc63f 56.77%,
    #c3922e 100%
  );
  border: solid 2px #ecc63f;
  border-radius: 5rem;
  color: #84621f;
  font-size: 24px;
  font-weight: 600;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.f14 {
  font-size: 14px;
}

.grey-text {
  color: #636363;
}

.ant-carousel .slick-dots li button {
  height: 4px !important;
  color: transparent !important;
}

.ant-carousel .slick-dots li.slick-active button {
  height: 4px !important;
  color: transparent !important;
}

.carousal-style {
  display: flex;
  margin: 0 0 2rem 0;
}
