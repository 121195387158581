.fleet-status-page .vehicle-card {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  flex-direction: column;
  border-radius: 8px;
}

.fleet-status-page .card-value {
  font-size: 86px;
  line-height: 90px;
  font-weight: 600;
  height: 75%;
  display: flex;
  align-items: center;
  padding: 16px;
}

.fleet-status-page .card-title {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding: 10px;
}

@media screen and (max-width: 576px) {
  .fleet-status-page .card-value {
    font-size: 32px;
    line-height: 36px;
  }

  .fleet-status-page .card-title {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .fleet-status-page .card-value {
    font-size: 46px;
    line-height: 48px;
  }

  .fleet-status-page .card-title {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (min-width: 993px) and (max-width: 1600px) {
  .fleet-status-page .card-value {
    font-size: 60px;
    line-height: 64px;
  }

  .fleet-status-page .card-title {
    font-size: 18px;
    line-height: 22px;
  }
}

.mh100 {
  min-height: 100%;
}
