@font-face {
  font-family: "Poppins";
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("/src/assets/Poppins-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

:root,
.ant-card,
[class^="ant-row"],
[class^="ant-col"],
.ant-radio-wrapper,
.ant-modal {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

[class*=" ant-btn"] {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: "Poppins", sans-serif;
}

.ant-layout-header {
  padding-inline: 10px;
}

.ant-dropdown-menu-submenu-title {
  padding-inline-end: 10px;
}

.margin-center {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.h1-header {
  font-size: 26px;
  font-weight: 600;
}

.sub-header {
  font-size: 14px;
  color: #475467;
}

.mt-space {
  margin: 1rem 0;
}

.head {
  font-weight: 600;
  font-size: 16px;
}

.grey-bg {
  background-color: rgb(240, 242, 245);
  min-height: 668px;
  display: flex;
}

.mt4 {
  margin-top: 4rem;
}

.ant-dropdown .ant-dropdown-menu {
  min-width: 165px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  padding: 8px 15px;
}

.ant-space {
  cursor: pointer;
}

.Toastify__close-button > button,
[type="button"] {
  background-color: unset !important;
  width: unset;
  font-size: unset;
  font-weight: unset;
  font-weight: unset;
  color: unset;
}

.mt1 {
  margin-top: 1rem;
}

.mt105 {
  margin-top: 1.5rem;
}

.mt1025 {
  margin-top: 1.25rem;
}

.mt1075 {
  margin-top: 1.75rem;
}

.mt05 {
  margin-top: 0.5rem;
}

.mt025 {
  margin-top: 0.25rem;
}

.mt075 {
  margin-top: 0.75rem;
}

.icon-margin {
  margin-top: 1.25rem;
  margin-right: 0.5rem;
}

.mb0 {
  margin-bottom: 0rem !important;
}

.mb025 {
  margin-bottom: 0.25rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt2025 {
  margin-top: 2.25rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.mr1 {
  margin-right: 1rem;
}

.mr05 {
  margin-right: 0.5rem;
}

.ml05 {
  margin-left: 0.5rem;
}

.ml025 {
  margin-left: 0.25rem;
}

.ml015 {
  margin-left: 0.15rem;
}

.mlr0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.mtb1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ant-menu-dark {
  color: rgba(255, 255, 255, 0.85);
}

.icon-size {
  width: 18px;
  height: 18px;
}

.start {
  font-weight: 600;
  font-size: 0.75rem;
  color: #fff;
  background-color: #039855;
  padding: 0.5rem 1.5rem;
  border-radius: 1.5rem;
}

.start:hover {
  color: #fff;
  background-color: #03b163;
}

.start:disabled {
  color: #fff;
  background-color: #90ae8a !important;
}

.stop {
  font-weight: 600;
  font-size: 0.75rem;
  color: #fff;
  background-color: #df2403;
  padding: 0.5rem 1.5rem;
  border-radius: 1.5rem;
}

.stop:hover {
  color: #fff;
  background-color: #f22703;
}

.stop-disabled:hover {
  color: #fff;
}

a[disabled] {
  color: #fff;
}

.stop-disabled {
  font-weight: 600;
  font-size: 0.75rem;
  color: #fff;
  background-color: #fd735b;
  padding: 0.5rem 1.5rem;
  border-radius: 1.5rem;
}

.ant-btn-primary.ant-btn-dangerous:disabled {
  background-color: #ff9a9b !important;
}

.ant-btn-primary.ant-btn-dangerous {
  background-color: #ff4d4f !important;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-btn.ant-btn-icon-only {
  height: auto !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

.ant-btn-submit {
  background-color: #f16521 !important;
}

.ant-btn-submit:disabled,
.ant-btn-submit.ant-btn-disabled {
  background-color: #f1652150 !important;
}

a {
  color: #f16521;
}

a:hover {
  color: #d24d0d;
}

.dp {
  background-color: #d9d9d9;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #f16521;
}

.bold {
  font-weight: 600;
}

.font-weight-normal {
  font-weight: normal;
}

:where(.css-dev-only-do-not-override-12upa3x) > button,
.ant-btn .ant-btn-default {
  color: rgba(0, 0, 0, 0.88) !important;
}

.title {
  color: #1d2939;
}

.card-sub-text,
.value {
  color: #667085;
}

.card-bold-text {
  color: #1d2939;
  font-weight: 600;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1d2939;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #1d2939;
}

.ant-tabs .ant-tabs-tab-btn {
  color: #667085;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #1d2939;
}

.mode-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.ant-modal-footer > .ant-btn-submit {
  background-color: #fff;
  color: #000;
}

.ant-modal-close {
  color: rgba(0, 0, 0, 0.45) !important;
  height: 0 !important;
}

.ant-modal-close-x {
  background: #000;
  border-radius: 1rem;
  color: #fff;
  font-size: 14px;
  padding: 0.2rem;
  font-weight: 600;
}

.comment-btn {
  border-radius: 40px !important;
  background: #1d2939 !important;
  max-height: 38px !important;
  width: 100px !important;
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-form-item .ant-form-item-explain-error {
  margin-bottom: 1rem;
}

.ant-spin .ant-spin-dot-item {
  background-color: #f16521;
}

.ant-breadcrumb {
  color: #7a7a7a;
}

.ant-layout-header {
  line-height: 50px;
}

.ant-form-item .ant-form-item-label {
  display: none;
}

.form-group select {
  font-size: 16px;
  height: 38px;
}

.ant-select-multiple .ant-select-selection-overflow {
  height: 40px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-baseline {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.no-padding-w-box {
  background-color: #fff;
  border-radius: 10px;
}

.box {
  border: solid 1px #eee;
  padding: 15px;
  border-radius: 10px;
}

.border {
  border: solid 1px #eee;
  border-radius: 10px;
}

.ant-pagination-item-link {
  color: #aeaeae !important;
  height: 35px !important;
}

.fs1 {
  font-size: 1rem;
}

.fs1015 {
  font-size: 1.15rem;
}

.ant-card .ant-card-head,
.ant-card .ant-card-body {
  padding: 10px;
}

.fs14 {
  font-size: 14px;
}

.fs12 {
  font-size: 12px;
}

.overview-header {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.ant-card-head:has(.overview-header) {
  min-height: unset;
}

.fs13 {
  font-size: 13px;
}

.ant-table-wrapper .ant-table-tbody > tr > td,
.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 10px 12px;
}

.count {
  color: #1d2939;
  font-size: 1.65rem;
}

.avg-count {
  font-size: 26px;
}

canvas {
  border-radius: 0.5rem;
}

.ant-tabs .ant-tabs-tab {
  font-size: 16px;
}

.ant-card .ant-card-grid-hoverable {
  box-shadow: none;
}

.ant-card .ant-card-grid-hoverable:hover {
  box-shadow: none;
}

.ant-card .ant-card-grid {
  padding: 15px;
}

.ant-card .ant-card-head {
  font-size: 14px;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100% !important;
}

.mode-table .ant-table-thead > tr > th {
  font-weight: 500;
  padding: 6px 12px;
}

.mode-table .ant-table-tbody > tr > td {
  border-bottom: 0;
}

.dyno-mode-table .ant-table-thead > tr > th {
  font-weight: 500;
  padding: 6px 12px;
}

.dyno-mode-table .ant-table-thead > tr {
  border-radius: 0;
}

.dyno-mode-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0;
}

.dyno-mode-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0;
}

.ant-segmented .ant-segmented-group {
  border: solid 1px #e4e4e4;
  border-radius: 4px;
}

.ant-card .ant-card-actions > li > span {
  cursor: unset;
}

.ant-card .ant-card-actions > li > span:hover {
  color: unset;
}

.ant-card .ant-card-actions > li {
  margin: 8px 0;
}

.ride-data .ant-row {
  padding: 5px 0;
}

.error {
  color: #df2403;
}

.p0 {
  padding: 0 !important;
}

.pd05 {
  padding: 0.5rem;
}

.pd1 {
  padding: 1rem;
}

.pl1 {
  padding-left: 1rem !important;
}

.pd2015 {
  padding: 2.15rem;
}

.pd3025 {
  padding: 3.25rem;
}

.pdtb05 {
  padding: 0.5rem 0;
}

.pdtb1 {
  padding: 1rem 0;
}

.disabled {
  color: #ababab;
}

.mtb05 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

textarea {
  min-height: 85px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 6px 0;
}

.ant-empty-normal {
  margin-block: 26px;
}

.vehicle-drawer {
  opacity: 0.92;
  background-color: #fcfcfc;
  background-image: linear-gradient(#fcfcfc, #e8e8e8);
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  border: solid 1px #f2f2f2;
  display: flex;
  justify-content: center;
}

.vehicle-drawer .ant-drawer-body {
  padding: 4px 15px;
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  box-shadow: none;
}

.hide {
  display: none;
}

.vehicle-img {
  cursor: pointer;
}

.vehicle-img:hover + .show {
  display: block flex;
  justify-content: center;
}

.ant-card-contain-grid {
  border-radius: 8px;
}

.box-shadow {
  box-shadow: 3px 3px 4px #e4e4e4;
}

.border-right {
  border-right: 1px solid #f0f0f0 !important;
}

.disabled {
  color: #bcbcbc;
}

.anticon-caret-down {
  margin: 0 5px 0 10px;
}
