.grid {
  width: 16.5% !important;
  text-align: center !important;
}

.mobile-hide {
  display: block;
}

@media screen and (max-width: 768px) {
  .grid {
    width: 100% !important;
  }
  .mobile-hide {
    display: none;
  }
}

.ant-notification-notice.compare-notification {
  padding: 0px;
  width: 160px;
  overflow: unset;
}

.compare-modal {
  height: 84%;
  overflow: hidden;
}

.grafana-compare .ant-card-body {
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.compare-modal .ant-modal-close-x {
  color: #444444;
  background-color: #ffffff;
}

.ride-statistic-analytics-card .ant-card-head {
  padding: 0px;
  min-height: unset;
}

.ride-statistic-analytics-card .ant-card-head-title .ant-row {
  padding: 12px;
  min-height: 56px;
  cursor: pointer;
  align-items: center;
}

.add-compare {
  font-size: 16px;
  font-weight: 600;
}

.add-compare .ant-checkbox-inner {
  height: 24px;
  width: 24px;
}

.add-compare .ant-checkbox-inner::after {
  height: 14px;
  width: 7px;
}

.grafana-compare .ant-switch {
  width: 10px !important;
  font-size: 16px !important;
  height: 22px !important;
  background: rgba(0, 0, 0, 0.25) !important;
}

.grafana-compare .ant-switch.ant-switch-checked {
  background: #f16521 !important;
}

.grafana-compare-body {
  height: 100%;
  overflow: scroll;
}

.add-compare .ant-card-body {
  border: 1px solid #f16521;
  border-radius: 6px;
}